import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import DownloadApp from '../components/DownloadApp';
import { Link } from "gatsby"

export default () => (
  <Layout>
    <SEO title="Annie Marie's testimonial" />

    <div>
      <div className="section bg-gray-4">
        <div className="container">
          <div className="row row-split-content full-width">
            <div className="content-width-medium">
              <h1 className="h3"> "I’m attracting more loving people into my life. You are what you believe you are.”</h1>
              <div className="div-block"><img width={74} src="/images/5ddc4bc87dc9835069f5ccfc_18-People%20Avatar-Woman.png" alt="" className="case-study-logo" />
                <div>
                  <div className="handwriting">Annie Marie</div>
                  <div>Wise Guide User</div>
                </div>
              </div>
            </div>
            <div className="content-width-large"><img src="/images/5ddcc43ed52c2f930dc5ab03_happy-woman_t20_knGbWX.jpg" alt="" sizes="(max-width: 479px) 95vw, (max-width: 767px) 90vw, (max-width: 991px) 612px, 46vw" srcSet="/images/5ddcc43ed52c2f930dc5ab03_happy-woman_t20_knGbWX-p-800.jpeg 800w, /images/5ddcc43ed52c2f930dc5ab03_happy-woman_t20_knGbWX.jpg 1048w" /></div>
          </div>
        </div>
      </div>
      <div className="section bg-gray-4">
        <div className="container">
          <div className="w-layout-grid sidebar-layout case-study-layout">
            <div className="sidebar">
              <div className="card shadow-small">
                <ul className="w-list-unstyled">
                  <li className="card-body border-bottom">
                    <h6 className="h6-small no-bottom-space">Name</h6>
                    <div>Annie Marie</div>
                  </li>
                  <li className="card-body border-bottom">
                    <h6 className="h6-small no-bottom-space">Programs</h6>
                    <div>Become Super Self Confident…Automatically</div>
                  </li>
                  <li className="card-body border-bottom">
                    <h6 className="h6-small no-bottom-space">Results</h6>
                    <div>Now, I’m outgoing and don’t dwell on negative thoughts. As I result, I’m attracting more loving people into my life. You are what you believe you are.</div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="content-width-large">
                <div className="article w-richtext">
                  <p>Can you change? Can you undo years of programming and live the kind of life you want to? In this inspiring story, one woman reveals how she radically changed her attitude and approach to life.</p>
                  <p>Today, gently spoken Annie Marie says you are what you believe but for many years, her self-belief was distorted and damaged. “I moved to Florida in 1989 with my second husband who then decided he didn’t want to be married any more. I didn’t want to be alone so I jumped straight into a new relationship. I needed someone to love me.” Annie Marie found herself trapped in a negative relationship for thirteen years worsening her already fragile self-confidence. “It got to a point where I couldn’t handle it any more. I was isolated and swamped in negativity.”</p>
                  <p>Wandering through a thrift store in Florida, Annie Marie found an audio self help program that would change her life. “It was just after leaving the relationship,” she says. “I had no self-confidence, I was alone for the first time in my life and I knew I needed to make changes. And then I spotted this second-hand tape in a thrift store.” The tape was ‘Become Super Self Confident…Automatically’ by Bob and Deirdre Griswold of Effective Learning Systems. “I started listening to the program over and over again hearing Bob and Deirdre explain how to reject negative thoughts and use positive affirmations to change how you think and feel about yourself.”</p>
                  <p>Part the process for Annie Marie was learning to accept and love herself. “It’s something I hadn’t done and led me to make choices that weren’t good for me because I didn’t think I deserved to be happy. Now, I choose to be happy. I was a very inward person but the tape explained how to eject negatives and to choose to be confident. I started by smiling at other people and of being loving towards them. You get back what you give out.”</p>
                  <p>Annie Marie used to worry what people thought of her and often avoided social situations. “I used to be easily intimidated until I realized most people feel the same way you do. Rather than focusing on your insecurities, if you start putting out love and think about the other person you feel better and they feel better. I also learned to stop criticizing myself all the time and I stopped being judgmental. Using positive affirmations really helped turn my self-belief around. So much happens in your subconscious so the more positive messages you plant in there, the better.”</p>
                  <p>With a strong faith and new self-confidence, Annie Marie describes herself as blessed. “Now, I’m outgoing and don’t dwell on negative thoughts. And I make sure to say hi to someone at least three times a day. I put out the love thought. As I result, I’m attracting more loving people into my life. You are what you believe you are.”</p>
                  <p>‍</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section bg-gray-4">
        <div className="container">
          <div className="row row-justify-center">
            <div className="section-title-wide">
              <h3 className="h2 no-bottom-space">More success stories</h3>
            </div>
          </div>
          <div className="w-dyn-list">
            <div className="case-study-cards-grid w-dyn-items">
              <div className="w-dyn-item">
                <div className="card case-study-card">
                  <div className="card-body case-study-card-body"><img src="/images/5ddc49d9315b303c82a6af7b_15-People%20Avatar-Man.png" alt="" className="case-study-logo" />
                    <p className="case-study-card-excerpt">The programs have enabled me to increase my focus on positive outcomes. The process of listening for 30 days seems to be an effective way to drive home points about positive approaches to problems</p><Link to="/dorsey/">Read their story</Link>
                  </div>
                </div>
              </div>
              <div className="w-dyn-item">
                <div className="card case-study-card">
                  <div className="card-body case-study-card-body"><img src="/images/5ddc498444a1cc7c9762354a_26-People%20Avatar-Woman.png" alt="" className="case-study-logo" />
                    <p className="case-study-card-excerpt">Jenny was able to gain more confidence and lose weight using the Wise Guide app</p><Link to="/jenny/">Read their story</Link>
                  </div>
                </div>
              </div>
              <div className="w-dyn-item">
                <div className="card case-study-card">
                  <div className="card-body case-study-card-body"><img src="/images/5ddc49cd315b3039bba6af51_13-People%20Avatar-Man.png" alt="" className="case-study-logo" />
                    <p className="case-study-card-excerpt">Marlin lost a staggering 70 pounds using The Wise Guide weight loss program</p><Link to="/marlin/">Read their story</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <DownloadApp />
  </Layout>
);
